const FONTS: {
  readonly primary: "Inter";
  readonly heading: "Darker Grotesque";
  readonly button: "Inter";
  readonly tab: "Inter";
  readonly caption: "Harmattan";
} = {
  primary: "Inter",
  heading: "Darker Grotesque",
  button: "Inter",
  tab: "Inter",
  caption: "Harmattan",
};

const FONT_WEIGHTS: {
  readonly heavy: 800;
  readonly heavier: 700;
  readonly bold: 600;
  readonly medium: 500;
  readonly normal: 400;
} = {
  heavy: 800,
  heavier: 700,
  bold: 600,
  medium: 500,
  normal: 400,
};

const FONT_SIZES: {
  readonly hero: "2.5rem";
  readonly display: "2.25rem";
  readonly title: "1.5rem";
  readonly body: "1rem";
  readonly caption: "0.8rem";
  readonly button: "0.75rem";
  readonly base: "15px";
} = {
  hero: "2.5rem",
  display: "2.25rem",
  title: "1.5rem",
  body: "1rem",
  caption: "0.8rem",
  button: "0.75rem",
  base: "15px",
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { FONTS, FONT_WEIGHTS, FONT_SIZES };
