const COLOR_PALETTE = {
  sand: "#FBF8F0",
  black: "#1C2528",
  black2: "#646A69", //todo better name
  black3: "#BABCBC", //todo better name
  amber: "#DB6D4F",
  gold: "#FFAD3B",
  gold2: "#E69C35", //todo better name
  gold3: "#CC8A2F", //todo better name
  navy: "#14444F",
  lavender: "#9FADFF",
  kellyGreen: "#009280",
  grey20: "#DAD8D2",
  offWhite: "#FEFDFB",
  white: "#FFFFFF",
  transparent: "transparent",
  transparent2: "rgba(28, 37, 40, 0.05)", //todo better name
  transparent3: "rgba(28, 37, 40, 0.1)", //todo better name
  transparent4: "rgba(28, 37, 40, 0.15)", //todo better name
};

export default COLOR_PALETTE;
