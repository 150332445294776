import { ThemeProvider } from "@emotion/react";
import themes from "src/styles/theme";
import { globalStyles } from "./style";
import { ReactNode, useState } from "react";

const Layout = ({ children }: { children: ReactNode }) => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const activeTheme = isDarkMode ? themes.darkTheme : themes.lightTheme;

  const themeValue = {
    ...activeTheme,
    toggleTheme: () => setIsDarkMode((prevState) => !prevState),
  };

  return (
    <>
      <ThemeProvider theme={themeValue}>
        {globalStyles}
        {children}
      </ThemeProvider>
    </>
  );
};

export default Layout;
