import type { AppProps } from "next/app";
import Layout from "src/components/layout";
import { SessionProvider } from "next-auth/react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function MyApp({ Component, pageProps: { session, ...pageProps } }: AppProps) {
  return (
    <>
      <SessionProvider session={session}>
        <DndProvider backend={HTML5Backend}>
          <Layout>
            <Component {...pageProps} />
          </Layout>
        </DndProvider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
