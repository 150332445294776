import COLORS from "./colors";
import TYPOGRAPHY from "./typography";
const lightTheme = {
  COLORS: {
    ...COLORS,
    background: {
      primary: COLORS.offWhite,
      secondary: COLORS.white,
    },
    text: {
      primary: COLORS.black,
      secondary: COLORS.black2, //todo
      tertiary: COLORS.black3, //todo
    },
    border: {
      primary: COLORS.grey20,
    },
    button: {
      danger: COLORS.amber,
      primary: COLORS.gold,
      primaryHover: COLORS.gold2,
      primaryPressed: COLORS.gold3,
      secondary: COLORS.transparent2,
      secondaryHover: COLORS.transparent3,
      secondaryPressed: COLORS.transparent4,
      tertiary: COLORS.transparent,
      tertiaryHover: COLORS.transparent2,
      tertiaryPressed: COLORS.transparent3,
    },
  },
  TYPOGRAPHY,
};

export default lightTheme;
