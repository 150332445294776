import styled from "@emotion/styled";
import { css, Global } from "@emotion/react";

const fonts = [
  {
    family: "Darker+Grotesque",
    weights: [400, 600, 700, 800],
  },
  {
    family: "Harmattan",
    weights: [400, 600, 700, 800],
  },
  {
    family: "Inter",
    weights: [400, 500, 600],
  },
];

const importUrl = `https://fonts.googleapis.com/css2?${fonts
  .map((font) => `family=${font.family}:wght@${font.weights.join(";")}`)
  .join("&")}&display=swap`;

export const globalStyles = (
  <Global
    styles={(theme) => css`
      @import url(${importUrl});
      html,
      body {
        padding: 0;
        margin: 0;
        font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
          Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
        font-size: ${theme.TYPOGRAPHY.FONT_SIZES.base};
      }

      a {
        color: inherit;
      }

      * {
        box-sizing: border-box;
      }

      html,
      body {
        background: ${theme.COLORS.background.primary};
        color: ${theme.COLORS.text.primary};
        font-family: ${theme.TYPOGRAPHY.FONTS.primary};
        transition: background 500ms ease, color 500ms ease,
          font-family 500ms ease;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: ${theme.TYPOGRAPHY.FONTS.heading};
        font-weight: ${theme.TYPOGRAPHY.FONT_WEIGHTS.heavy};
        margin: 0;
        line-height: 100%;
      }

      h1 {
        font-size: ${theme.TYPOGRAPHY.FONT_SIZES.hero};
      }

      h2 {
        font-size: ${theme.TYPOGRAPHY.FONT_SIZES.display};
        font-weight: ${theme.TYPOGRAPHY.FONT_WEIGHTS.heavier};
        line-height: 125%;
      }

      h3 {
        font-size: ${theme.TYPOGRAPHY.FONT_SIZES.title};
        font-weight: ${theme.TYPOGRAPHY.FONT_WEIGHTS.bold};
        font-family: ${theme.TYPOGRAPHY.FONTS.primary};
      }

      h4,
      h5,
      h6 {
        font-family: ${theme.TYPOGRAPHY.FONTS.primary};
      }

      p {
        font-size: ${theme.TYPOGRAPHY.FONT_SIZES.body};
        font-weight: ${theme.TYPOGRAPHY.FONT_WEIGHTS.normal};
        line-height: 135%;
      }
    `}
  />
);
