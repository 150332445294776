import COLORS from "./colors";
import lightTheme from "./lightTheme";

const darkTheme = {
  ...lightTheme,
  COLORS: {
    ...lightTheme.COLORS,
    background: {
      ...lightTheme.COLORS.background,
      primary: COLORS.black,
      secondary: COLORS.black,
    },
    text: {
      ...lightTheme.COLORS.text,
      primary: COLORS.offWhite,
      secondary: COLORS.offWhite,
      tertiary: COLORS.offWhite,
    },
  },
};

export default darkTheme;
